import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home" />
    <ul>
      {data.allMarkdownRemark.nodes.map(node => (
        <li>
          <Link to={node.frontmatter.path}>{node.frontmatter.title}</Link>
        </li>
      ))}
    </ul>
  </Layout>
)

export const pageQuery = graphql`
  query {
    allMarkdownRemark(filter: { frontmatter: { date: { ne: null } } }) {
      nodes {
        frontmatter {
          date
          path
          title
        }
      }
    }
  }
`

export default IndexPage
