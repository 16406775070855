/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import styles from "./layout.module.scss"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        buildTime(formatString: "YYYY-MM-DD")
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className={styles.body}>
      <header className={styles.header}>
        <div className={styles.headerContent}>
          <Link to="/">{data.site.siteMetadata.title}</Link>
          <div className={styles.headerLinks}>
            <Link to="/about">About</Link>
          </div>
        </div>
      </header>
      <main className={styles.main}>{children}</main>
      <footer className={styles.footer}>
        <div className={styles.footerContent}>
          © {new Date().getFullYear()}, last updated on {data.site.buildTime}
        </div>
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
